<template>
  <section v-if="data" class="o-about-light">
    <div class="container">
      <div class="o-about-light__container">
        <h2 class="o-about-light__title">{{ data.title }}</h2>

        <div class="o-about-light__cards">
          <Card v-for="(card, i) in data.cards" :key="i" css-class="m-card-info" modifier="iconic">
            <template #contentHeader>
              <i :class="`icon-${card.icon}`"></i>
            </template>

            <template #content>
              <div class="m-card-info__content-headline">{{ card.title }}</div>
              {{ card.text }}
            </template>
          </Card>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Card from '@ice-products-ui/vue-library/Card';

// Composables
import useGetData from '~/composables/api/useGetData';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import type { TOAboutLight } from '~/components/O/About/Light/OAboutLight.types';

// Schema
import schema from '~/components/O/About/Light/OAboutLight.schema';

defineOptions({
  name: 'OAboutLight',
});

const props = withDefaults(defineProps<IComponentProps<TOAboutLight>>(), {
  viewName: 'OAboutLight',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOAboutLight>();

const data = ref<TOAboutLight>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
